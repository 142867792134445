import {
  Avatar,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';

import React, { useLayoutEffect, useState } from 'react';
import { MdAddAPhoto, MdClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { GET_POST_BY_ID, UPDATE_POST } from '../Redux/Actions/PostAction';
import Saving from './Saving';
import { GOOGLE_API_KEY, URL as ImageURL } from '../config';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import Loading from './Loading';
import { BiCurrentLocation } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';
import { RiVideoAddFill } from 'react-icons/ri';
import moment from 'moment';
import { GET_STORE_LIST } from '../services/stores';

const libraries = ['places', 'geometry', 'drawing'];

const ERROR = (msg) => {
  return toast.error(msg, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
const category = [
  'Select a Option',
  'Charity',
  'Grocery Stores',
  'Businesses with Free Things To Offer',
  'Online Service and Sales',
];

const EditPost = ({ id, handleClose }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries,
  });
  const [stores, setStores] = useState([]);
  const [offer, setOffer] = useState(null);
  const dispatch = useDispatch();
  const [model, setModel] = useState(false);

  useLayoutEffect(() => {
    const fetchStores = async () => {
      try {
        const stores = await GET_STORE_LIST();

        setStores(stores);
      } catch (error) {}
    };
    fetchStores();
    dispatch(
      GET_POST_BY_ID(id, (v) => {
        console.log(v);
        let a = {
          ...v,
          cover: {
            uri: `${ImageURL}/media/post/cover/${v.cover.uri}`,
            name: v.cover.name,
          },
          isCover: false,
          ph: '0' + v.ph,
          location: {
            lng: v.location.coordinates[0],
            lat: v.location.coordinates[1],
          },
          pic: v.pic.map((item) => {
            return {
              uri: `${ImageURL}/media/post/images/${item.uri}`,
              name: item.name,
              isNew: false,
              isDeleted: false,
            };
          }),
          store: v?.store?._id || '',
        };
        if (a.video) {
          a.video = {
            uri: `${ImageURL}/media/post/video/${a.video.uri}`,
            name: a.video.name,
            isNew: false,
          };
          a.isVideo = false;
        }
        setOffer(a);

        setTimeout(() => {
          setCenter({
            lng: v.location.coordinates[0],
            lat: v.location.coordinates[1],
          });
          setSelectedLocation({
            lng: v.location.coordinates[0],
            lat: v.location.coordinates[1],
          });
          setLoading(true);
        }, 3000);
      })
    );
    // eslint-disable-next-line
  }, []);

  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState();
  const [center, setCenter] = useState(null);
  const [deletedVideo, setDeletedVideo] = useState(null);
  const [deletedCover, setDeletedCover] = useState(null);

  const getCurrentPosition = () =>
    new Promise((resolve, reject) => {
      try {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            resolve({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          (error) => reject(error)
        );
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });

  const getPositionPermission = async () => {
    try {
      if (!('geolocation' in navigator))
        throw new Error('Location Not Available!');
      const location = await navigator.permissions.query({
        name: 'geolocation',
      });
      if (location.state !== 'granted') {
        navigator.geolocation.getCurrentPosition(() => {});
        location.addEventListener('change', async () => {
          const location = await getCurrentPosition();
          setLocation(location);
          setTimeout(() => {
            setLoading(true);
          }, 3000);
        });
      } else {
        const cord = await getCurrentPosition();
        setLocation(cord);
        setTimeout(() => {
          setLoading(true);
        }, 3000);
      }
    } catch (error) {
      setLoading(true);
      alert(error.message);
    }
  };

  // useLayoutEffect(() => {
  //   getPositionPermission();
  // }, []);

  const handleResetCover = () => {
    setOffer({ ...offer, cover: null });
  };

  const handelSubmit = () => {
    if (!offer.cover) {
      ERROR('Post Cover is required!');
      return;
    }

    if (offer.category.text === 'Grocery Stores' && !offer.store) {
      ERROR('Associated store is required');
      return;
    }

    let data = new FormData();

    let location = {
      type: 'Point',
      coordinates: [selectedLocation.lng, selectedLocation.lat],
    };

    data.append('address', offer.address);
    data.append('location', JSON.stringify(location));
    data.append('category', JSON.stringify(offer.category));
    data.append('short_description', offer.short_description);
    data.append('description', offer.description);
    data.append('ph', offer.ph);
    data.append('website', offer.website);
    data.append('expiresAt', moment(offer.expiresAt).toISOString());
    data.append('email', offer.email);
    data.append('id', offer.user._id);
    if (offer.category.text === 'Grocery Stores')
      data.append('store', offer.store);

    data.append('postId', id);

    if (offer.isCover) {
      data.append('updated_cover', offer.cover);
    }
    if (deletedCover) {
      data.append('deleted_cover', deletedCover.name);
    }
    if (offer.video && offer.video?.isNew) {
      data.append('updated_video', offer.video);
    }
    if (deletedVideo) {
      data.append('deleted_video', deletedVideo.name);
    }

    let new_pic = offer.pic.filter((item) => item.isNew);
    if (new_pic.length > 0) {
      for (const i of new_pic) {
        let pic = i;
        delete pic.isNew;
        delete pic.isDeleted;
        console.log(pic);
        data.append('updated_pic', pic.file);
      }
    }
    let old_pic = offer.pic.filter((item) => item.isDeleted && !item.isNew);
    if (old_pic.length > 0) {
      for (const i of old_pic) {
        data.append('deleted_pic[]', i.name);
      }
    }

    console.log(data);
    setModel(true);
    dispatch(
      UPDATE_POST(data, () => {
        setModel(false);
        handleClose();
      }),
      () => setModel(false)
    );
  };

  if (!loading && !center) return <Loading />;
  // else if (!location)
  //   return (
  //     <React.Fragment>
  //       <div style={{ textAlign: 'center', fontSize: 24, fontWeight: 500 }}>
  //         Location not Available !
  //       </div>
  //     </React.Fragment>
  //   );
  return (
    <div>
      <Saving visible={model} title='Saving' />
      <div>
        {isLoaded && (
          <React.Fragment>
            <div>
              <GooglePlacesAutocomplete
                apiKey={GOOGLE_API_KEY}
                onLoadFailed={(er) => console.log(er)}
                debounce={1000}
                selectProps={{
                  onChange: async (data) => {
                    const result = await geocodeByPlaceId(data.value.place_id);
                    setCenter({
                      lat: result[0].geometry.location.lat(),
                      lng: result[0].geometry.location.lng(),
                    });
                    setSelectedLocation({
                      lat: result[0].geometry.location.lat(),
                      lng: result[0].geometry.location.lng(),
                    });
                  },
                }}
              />
            </div>
            <div
              style={{
                height: 700,
                width: '100%',
                marginTop: 12,
              }}
            >
              <GoogleMap
                mapContainerStyle={{
                  height: 700,
                  width: '100%',
                }}
                center={center}
                zoom={14}
                onLoad={async (map) => {
                  // const bounds = new window.google.maps.LatLngBounds();
                  // map.panTo(center);
                  // map.fitBounds(bounds);
                }}
                onUnmount={(map) => {
                  // do your stuff before map is unmounted
                }}
                onClick={(value) => {
                  setCenter({
                    lat: value.latLng.lat(),
                    lng: value.latLng.lng(),
                  });
                  setSelectedLocation({
                    lat: value.latLng.lat(),
                    lng: value.latLng.lng(),
                  });
                }}
              >
                <Marker position={location} />
                {selectedLocation && (
                  <Marker
                    position={selectedLocation}
                    draggable={true}
                    onDragEnd={(value) => {
                      setSelectedLocation({
                        lat: value.latLng.lat(),
                        lng: value.latLng.lng(),
                      });
                    }}
                  />
                )}
                <div
                  style={{
                    position: 'absolute',
                    bottom: '28%',
                    right: '0.7%',
                  }}
                >
                  <IconButton
                    style={{ backgroundColor: 'white' }}
                    onClick={async () => {
                      const location = await getCurrentPosition();
                      setLocation(location);
                      setCenter(location);
                    }}
                  >
                    <BiCurrentLocation />
                  </IconButton>
                </div>
              </GoogleMap>
            </div>
          </React.Fragment>
        )}

        <div>
          <Typography variant='h6' style={{ marginBlock: 14 }}>
            Cover
          </Typography>
          {offer.cover ? (
            <div style={{ position: 'relative', width: 300 }}>
              <Avatar
                src={
                  offer.isCover
                    ? URL.createObjectURL(offer.cover)
                    : offer.cover.uri
                }
                alt='Image'
                style={{
                  height: 250,
                  width: 250,
                }}
                variant='rounded'
              />
              <IconButton
                style={{ position: 'absolute', right: 0, top: -10 }}
                onClick={handleResetCover}
              >
                <AiOutlineClose />
              </IconButton>
            </div>
          ) : (
            <div style={{ flexDirection: 'row' }}>
              <input
                accept='image/*'
                style={{ display: 'none' }}
                id='icon-button-file2'
                type='file'
                onChange={async (e) => {
                  if (e.target.files[0]?.size / (1024 * 1024) <= 10) {
                    if (!deletedCover) setDeletedCover(offer.cover);
                    let a = {
                      ...offer,
                      cover: e.target.files[0],
                      isCover: true,
                    };
                    setOffer(a);
                  } else {
                    toast.error('Image Size is Larger than 10MB');
                  }
                }}
              />
              <label htmlFor='icon-button-file2' style={{ display: 'flex' }}>
                <Typography
                  variant='subtitle1'
                  align='left'
                  style={{ paddingTop: 10, marginLeft: 20 }}
                >
                  Add Cover
                </Typography>
                <IconButton
                  color='primary'
                  aria-label='upload picture'
                  component='span'
                >
                  <MdAddAPhoto />
                </IconButton>
              </label>
            </div>
          )}
        </div>

        <div>
          <Typography variant='h6' style={{ marginBlock: 14 }}>
            Description
          </Typography>
          <TextField
            multiline
            label='Description'
            variant='outlined'
            value={offer.description}
            onChange={(e) => {
              setOffer({ ...offer, description: e.target.value });
            }}
            style={{ width: '100%' }}
          />
        </div>

        <div>
          <Typography variant='h6' style={{ marginBlock: 14 }}>
            Category
          </Typography>
          <FormControl
            variant='outlined'
            style={{ width: '100%', marginBlock: 14 }}
          >
            <InputLabel id='demo-simple-select-outlined-label'>
              Category
            </InputLabel>
            <Select
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              value={offer.category.text}
              onChange={(e) => {
                setOffer({
                  ...offer,
                  category: {
                    text: e.target.value,
                    index: category.indexOf(e.target.value),
                  },
                });
              }}
              label={offer.category.text}
            >
              {category.map((item, i) => (
                <MenuItem value={item} key={i}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {offer.category.text === 'Grocery Stores' && (
          <div>
            <Typography variant='h6' style={{ marginBlock: 14 }}>
              Associated Store
            </Typography>
            <FormControl
              variant='outlined'
              style={{ width: '100%', marginBlock: 14 }}
            >
              <InputLabel id='demo-simple-select-outlined-label-2'>
                Associated Store
              </InputLabel>

              <Select
                labelId='demo-simple-select-outlined-label-2'
                id='demo-simple-select-outlined-2'
                value={stores.find((i) => i._id === offer.store)?.name}
                onChange={(e) => {
                  setOffer({
                    ...offer,
                    store: stores.find((i) => i.name === e.target.value)._id,
                  });
                }}
                label={stores.find((i) => i._id === offer.store)?.name || ''}
              >
                {stores.map((item, i) => (
                  <MenuItem value={item.name} key={i}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}

        <div>
          <Typography variant='h6' style={{ marginBlock: 14 }}>
            Expires At
          </Typography>
          <DateTimePicker
            label='Expires At'
            inputVariant='outlined'
            value={offer.expiresAt}
            onChange={(e) => {
              let a = { ...offer };
              a.expiresAt = e;
              setOffer(a);
            }}
            style={{ width: '100%' }}
          />
        </div>

        <div>
          <Typography variant='h6' style={{ marginBlock: 14 }}>
            Short Description
          </Typography>
          <TextField
            label='Short Description'
            variant='outlined'
            value={offer.short_description}
            onChange={(e) => {
              let a = { ...offer };
              a.short_description = e.target.value;
              setOffer(a);
            }}
            multiline
            style={{ width: '100%' }}
          />
        </div>

        <div>
          <Typography variant='h6' style={{ marginBlock: 14 }}>
            More Picture
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              overflow: 'auto',
              width: '98%',
            }}
          >
            {offer.pic
              .filter((item) => !item.isDeleted)
              .map((item, i) => {
                return (
                  <div key={i} style={{ position: 'relative' }}>
                    <IconButton
                      onClick={() => {
                        let a = { ...offer };
                        a.pic = offer.pic.map((pic) => {
                          return pic.name === item.name
                            ? { ...pic, isDeleted: true }
                            : pic;
                        });
                        setOffer(a);
                      }}
                      style={{ position: 'absolute', right: 4, zIndex: 1000 }}
                    >
                      <MdClose color='gray' />
                    </IconButton>
                    <Avatar
                      src={
                        item.isNew ? URL.createObjectURL(item.file) : item.uri
                      }
                      alt='Image'
                      style={{
                        height: 250,
                        width: 250,
                        margin: 7,
                        objectFit: 'contain',
                      }}
                      variant='rounded'
                    />
                  </div>
                );
              })}
          </div>

          <div style={{ flexDirection: 'row' }}>
            <input
              accept='image/*'
              style={{ display: 'none' }}
              id='icon-button-file'
              type='file'
              onChange={async (e) => {
                if (e.target.files[0]?.size / (1024 * 1024) <= 10) {
                  let a = { ...offer };
                  a.pic.push({
                    file: e.target.files[0],
                    isNew: true,
                    isDeleted: false,
                  });
                  console.log(a.pic);
                  setOffer(a);
                } else {
                  toast.error('Image Size is Larger than 10MB');
                }
              }}
            />
            <label htmlFor='icon-button-file' style={{ display: 'flex' }}>
              <Typography
                variant='subtitle1'
                align='left'
                style={{ paddingTop: 10, marginLeft: 20 }}
              >
                Add Picture
              </Typography>
              <IconButton
                color='primary'
                aria-label='upload picture'
                component='span'
              >
                <MdAddAPhoto />
              </IconButton>
            </label>
          </div>
        </div>

        <div style={{ flexDirection: 'row' }}>
          <Typography variant='h6' style={{ marginBlock: 14 }}>
            Video
          </Typography>
          <input
            accept='video/*'
            style={{ display: 'none' }}
            id='icon-button-file3'
            type='file'
            onChange={(e) => {
              if (e.target.files[0]?.size / (1024 * 1024) <= 10) {
                if (!deletedVideo) setDeletedVideo(offer.video);
                let a = { ...offer };
                a.video = e.target.files[0];
                setOffer(a);
              } else {
                toast.error('Image Size is Larger than 10MB');
              }
            }}
          />
          <label htmlFor='icon-button-file3' style={{ display: 'flex' }}>
            <Typography
              variant='subtitle1'
              align='left'
              style={{ paddingTop: 10, marginLeft: 20 }}
            >
              Upload Video
            </Typography>
            <IconButton
              color='secondary'
              aria-label='upload video'
              component='span'
            >
              <RiVideoAddFill />
            </IconButton>
            {offer.video === null ? null : (
              <Typography
                variant='subtitle1'
                style={{ paddingTop: 10 }}
                color='primary'
              >
                {offer.video?.name}{' '}
                {(offer.video?.size / (1024 * 1024)).toFixed(4)} MB
              </Typography>
            )}
          </label>
        </div>

        <div>
          <Typography variant='h6' style={{ marginBlock: 14 }}>
            Website
          </Typography>
          <TextField
            label='Website'
            variant='outlined'
            value={offer.website}
            onChange={(e) => {
              setOffer({ ...offer, website: e.target.value });
            }}
            style={{ width: '100%', marginBlock: 14 }}
          />
        </div>

        <div>
          <Typography variant='h6' style={{ marginBlock: 14 }}>
            Phone
          </Typography>
          <TextField
            label='Phone'
            variant='outlined'
            value={offer.ph}
            onChange={(e) => {
              setOffer({ ...offer, ph: e.target.value });
            }}
            style={{ width: '100%', marginBlock: 14 }}
          />
        </div>

        <div>
          <Typography variant='h6' style={{ marginBlock: 14 }}>
            Email
          </Typography>
          <TextField
            label='Email'
            variant='outlined'
            value={offer.email}
            onChange={(e) => {
              setOffer({ ...offer, email: e.target.value });
            }}
            style={{ width: '100%', marginBlock: 14 }}
          />
        </div>

        <div>
          <Typography variant='h6' style={{ marginBlock: 14 }}>
            Address
          </Typography>
          <TextField
            label='Address'
            variant='outlined'
            value={offer.address}
            onChange={(e) => {
              setOffer({ ...offer, address: e.target.value });
            }}
            style={{ width: '100%' }}
          />
        </div>

        <div style={{ marginBlock: 14 }}>
          <Button
            color='primary'
            variant='contained'
            size='large'
            onClick={handelSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditPost;
