import { Avatar, CircularProgress, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { URL } from '../config';
import { GET_POST_BY_ID } from '../Redux/Actions/PostAction';

const PostDetails = ({ id }) => {
  const edit = useSelector((state) => state.Post.edit_post);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      GET_POST_BY_ID(id, () => {
        setTimeout(() => {
          setLoading(true);
        }, 3000);
      })
    );
    // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);
  if (!loading) {
    return <CircularProgress size={100} thickness={2} />;
  } else
    return (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Avatar
                src={`${URL}/media/post/cover/${edit.cover.uri}`}
                alt='Image'
                style={{
                  height: 150,
                  width: 150,
                }}
                variant='rounded'
              />
              <div style={{ margin: 12 }}>
                <Typography variant='h6'>{edit.description}</Typography>
                <Typography variant='caption'>{edit.category.text}</Typography>
              </div>
            </div>
            <Typography
              variant='subtitle1'
              style={{ color: '#e65100', marginBlock: 14 }}
            >
              Expire In:{' '}
              {moment(edit?.expiresAt).format('MMMM Do YYYY, h:mm:ss a')}
            </Typography>
            <Typography variant='h6' style={{ marginBlock: 14 }}>
              Description
            </Typography>
            <Typography variant='body1' style={{ marginBlock: 14 }}>
              {edit?.short_description}
            </Typography>
          </div>
          <div style={{ flex: 1 }}>
            <Typography variant='h6' style={{ marginBlock: 14 }}>
              More Picture
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                overflow: 'auto',
                width: '98%',
              }}
            >
              {edit.pic.map((item, i) => {
                return (
                  <Avatar
                    key={i}
                    src={`${URL}/media/post/images/${item.uri}`}
                    alt='Image'
                    style={{
                      height: 150,
                      width: 150,
                      margin: 7,
                    }}
                    variant='rounded'
                  />
                );
              })}
            </div>
            <div style={{ display: 'flex', alignItems: 'start' }}>
              <Typography variant='h6'>Address:</Typography>
              <Typography variant='body1' style={{ margin: 5 }}>
                {edit.address}
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'start' }}>
              <Typography variant='h6'>Short Description:</Typography>
              <Typography variant='body1' style={{ margin: 5 }}>
                {edit.short_description}
              </Typography>
            </div>
          </div>
        </div>
        <Typography variant='h6'>Posted By:</Typography>
        <div style={{ display: 'flex', alignItems: 'start' }}>
          <Typography variant='h6'>User:</Typography>
          <Typography variant='body1' style={{ margin: 5 }}>
            {edit.user.name}
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'start' }}>
          <Typography variant='h6'>Email:</Typography>
          <Typography variant='body1' style={{ margin: 5 }}>
            {edit.user.email}
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'start' }}>
          <Typography variant='h6'>Phone:</Typography>
          <Typography variant='body1' style={{ margin: 5 }}>
            {edit.user.ph}
          </Typography>
        </div>
        {edit.category.text === 'Grocery Stores' && (
          <div style={{ display: 'flex', alignItems: 'start' }}>
            <Typography variant='h6'>Store:</Typography>
            <Typography variant='body1' style={{ margin: 5 }}>
              {edit?.store?.name}
            </Typography>
          </div>
        )}
      </div>
    );
};

export default PostDetails;
