export const IS_LOGGED_IN = "IS_LOGGED_IN";
export const IS_LOGGED_OUT = "IS_LOGGED_OUT";
export const TOKEN = "TOKEN";
export const ALL_ADMIN = "ALL_ADMIN";
export const ALL_LANGUAGE = "ALL_LANGUAGE";
export const ALL_CATEGORIES = "ALL_CATEGORIES";
export const ALL_USERS = "ALL_USERS";
export const ALL_WORKERS = "ALL_WORKERS";
export const ALL_PENDING_REQUEST = "ALL_PENDING_REQUEST";
export const ALL_COMPLAINT = "ALL_COMPLAINT";
export const COMMISSION = "COMMISSION";
export const DASHBOARD = "DASHBOARD";
export const ALL_POST = "ALL_POST";
export const USER_POST = "USER_POST";
export const CATEGORY_POST = "CATEGORY_POST";
export const EDIT_POST = "EDIT_POST";
