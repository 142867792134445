import * as ActionList from './ActionsList';
import API from '../../API/API';
import jwtdecode from 'jwt-decode';
import { toast } from 'react-toastify';
API.defaults.headers.common['x-auth-token'] =
  window.localStorage.getItem('Token');
const SUCCESS = (msg) => {
  return toast.success(msg, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const ERROR = (msg) => {
  return toast.error(msg, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const IS_LOGGED_IN = () => ({
  type: ActionList.IS_LOGGED_IN,
});

export const IS_LOGGED_OUT = () => ({
  type: ActionList.IS_LOGGED_OUT,
});

export const TOKEN = (payload) => ({
  type: ActionList.TOKEN,
  payload,
});

export const USER = (data) => {
  return async (dispatch) => {
    await window.localStorage.setItem('Token', data);
    API.defaults.headers.common['x-auth-token'] = data;
    dispatch(TOKEN(jwtdecode(data)));
    dispatch(IS_LOGGED_IN());
  };
};

export const USER_STATUS_IN = () => {
  return async (dispatch) => {
    const token = await window.localStorage.getItem('Token');
    if (token !== null) {
      dispatch(TOKEN(jwtdecode(token)));
      dispatch(IS_LOGGED_IN());
    }
  };
};

export const USER_STATUS_OUT = () => {
  return async (dispatch) => {
    await window.localStorage.removeItem('Token');
    dispatch(IS_LOGGED_OUT());
  };
};

export const LOGIN = (data, callback, errorCb) => {
  return async (dispatch) => {
    await API.post('/admin/login', data)
      .then((res) => {
        SUCCESS('Your are Logged In!');
        dispatch(USER(res.data));
        callback();
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR(error.request.data);
        } else {
          ERROR(error.message);
        }
        errorCb();
      });
  };
};

export const PROFILE_UPDATE = (data, callback, errorCb) => {
  return async (dispatch) => {
    await API.put('/admin/', data)
      .then((res) => {
        dispatch(TOKEN(res.data));
        SUCCESS('Profile Updated!');
        callback();
      })
      .catch((error) => {
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR(error.request.data);
        } else {
          ERROR(error.message);
        }
        errorCb();
      });
  };
};

export const ALL_ADMIN = (payload) => ({
  type: ActionList.ALL_ADMIN,
  payload,
});

export const GET_ADMIN_LIST = (callback) => {
  return async (dispatch) => {
    await API.get('/admin/')
      .then((res) => {
        dispatch(ALL_ADMIN(res.data));
        callback();
      })
      .catch((error) => {
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR(error.request.data);
        } else {
          ERROR(error.message);
        }
      });
  };
};

export const ALL_USERS = (payload) => ({
  type: ActionList.ALL_USERS,
  payload,
});

export const GET_USER = (email, callback) => {
  return async (dispatch) => {
    await API.get('/admin/user/' + email)
      .then((res) => {
        dispatch(ALL_USERS(res.data));
        callback();
      })
      .catch((error) => {
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR(error.request.data);
        } else {
          ERROR(error.message);
        }
      });
  };
};

export const GET_USER_BY_USERNAME = (name, callback) => {
  return async (dispatch) => {
    await API.get('/admin/username?search=' + name)
      .then((res) => {
        dispatch(ALL_USERS(res.data));
        callback();
      })
      .catch((error) => {
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR(error.request.data);
        } else {
          ERROR(error.message);
        }
      });
  };
};

export const ADD_ADMIN = (data, callback, errorCb) => {
  return async (dispatch) => {
    await API.post('/admin/', data)
      .then((res) => {
        dispatch(ALL_ADMIN(res.data));
        SUCCESS('Admin is Added!');
        callback();
      })
      .catch((error) => {
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR(error.request.data);
        } else {
          ERROR(error.message);
        }
        errorCb();
      });
  };
};

export const DELETE_ADMIN = (id, callback, errorCb) => {
  return async (dispatch) => {
    await API.delete('/admin/' + id)
      .then((res) => {
        dispatch(GET_ADMIN_LIST(callback));
        SUCCESS('Admin is Deleted!');
      })
      .catch((error) => {
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR(error.request.data);
        } else {
          ERROR(error.message);
        }
        errorCb();
      });
  };
};

export const ADD_PROMO = (data, callback, errorCb) => {
  return async (dispatch) => {
    await API.post('/promos/', data)
      .then((res) => {
        SUCCESS('Promo is Added!');
        callback();
      })
      .catch((error) => {
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR(error.request.data);
        } else {
          ERROR(error.message);
        }
        errorCb();
      });
  };
};

export const ALL_PENDING_REQUEST = (payload) => ({
  type: ActionList.ALL_PENDING_REQUEST,
  payload,
});

export const GET_PENDING_REQUEST = (callback) => {
  return async (dispatch) => {
    await API.get('/post/pending/')
      .then((res) => {
        dispatch(ALL_PENDING_REQUEST(res.data));
        callback();
      })
      .catch((error) => {
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR(error.request.data);
        } else {
          ERROR(error.message);
        }
        callback();
      });
  };
};

export const UPDATE_PENDING_REQUEST = (data, callback, errorCb) => {
  return async (dispatch) => {
    await API.put('/admin/postStatus', data)
      .then((res) => {
        SUCCESS('Verification is Completed!');
        dispatch(GET_PENDING_REQUEST(callback));
      })
      .catch((error) => {
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR(error.request.data);
        } else {
          ERROR(error.message);
        }
        errorCb();
      });
  };
};

export const DASHBOARD = (payload) => ({
  type: ActionList.DASHBOARD,
  payload,
});

export const GET_DASHBOARD = (callback) => {
  return async (dispatch) => {
    await API.get('/admin/dashboard')
      .then((res) => {
        dispatch(DASHBOARD(res.data));
        callback();
      })
      .catch((error) => {
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR(error.request.data);
        } else {
          ERROR(error.message);
        }
        callback();
      });
  };
};

export const ALL_COMPLAINT = (payload) => ({
  type: ActionList.ALL_COMPLAINT,
  payload,
});

export const GET_COMPLAINT = (callback) => {
  return async (dispatch) => {
    await API.get('/contactUs')
      .then((res) => {
        dispatch(ALL_COMPLAINT(res.data));
        callback();
      })
      .catch((error) => {
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR(error.request.data);
        } else {
          ERROR(error.message);
        }
        callback();
      });
  };
};

export const USER_CONTACT = (data, callback, errorCb) => {
  return async (dispatch) => {
    await API.post('/admin/contactUser', data)
      .then((res) => {
        SUCCESS('Email has been sent successfully!');
        callback();
      })
      .catch((error) => {
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR(error.request.data);
        } else {
          ERROR(error.message);
        }
        errorCb();
      });
  };
};
