import { toast } from 'react-toastify';
import API from '../API/API';

const SUCCESS = (msg) => {
  return toast.success(msg, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const ERROR = (msg) => {
  return toast.error(msg, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const GET_CATEGORIES_LIST = async () => {
  try {
    const res = await API.get('/admin/categories', {});
    return res.data;
  } catch (error) {
    if (error.response) {
      ERROR(error.response.data);
      throw new Error(error.response.data);
    } else if (error.request) {
      ERROR('Bad Request');
      throw new Error('Bad Request');
    } else {
      ERROR(error.message);
      throw new Error(error.message);
    }
  }
};

export const ADD_CATEGORY = async (data) => {
  try {
    const res = await API.post('/categories/byAdmin', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    SUCCESS('Category Added Successfully!');
    return res.data;
  } catch (error) {
    if (error.response) {
      ERROR(error.response.data);
      throw new Error(error.response.data);
    } else if (error.request) {
      ERROR('Bad Request');
      throw new Error('Bad Request');
    } else {
      ERROR(error.message);
      throw new Error(error.message);
    }
  }
};

export const UPDATE_CATEGORY = async (data) => {
  try {
    const res = await API.patch('/categories/byAdmin', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    SUCCESS('Category Updated Successfully!');
    return res.data;
  } catch (error) {
    if (error.response) {
      ERROR(error.response.data);
      throw new Error(error.response.data);
    } else if (error.request) {
      ERROR('Bad Request');
      throw new Error('Bad Request');
    } else {
      ERROR(error.message);
      throw new Error(error.message);
    }
  }
};

export const DELETE_CATEGORY = async (id) => {
  try {
    const res = await API.delete('/categories/byAdmin/' + id);
    SUCCESS('Category Deleted Successfully!');
    return res.data;
  } catch (error) {
    if (error.response) {
      ERROR(error.response.data);
      throw new Error(error.response.data);
    } else if (error.request) {
      ERROR('Bad Request');
      throw new Error('Bad Request');
    } else {
      ERROR(error.message);
      throw new Error(error.message);
    }
  }
};
