import {
  Avatar,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FaUserPlus } from 'react-icons/fa';
import { MdAdd, MdAddAPhoto, MdDeleteForever, MdEdit } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../Loading';
import Saving from '../Saving';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';
import {
  ADD_STORE,
  DELETE_STORE,
  GET_STORE_LIST,
  UPDATE_STORE,
} from '../../services/stores';
import { AiOutlineClose } from 'react-icons/ai';
import { URL as ApiURL } from '../../config';

const initial = {
  name: '',
  logo: null,
};

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().min(8).required(),
});

const AddStore = ({ history }) => {
  const IS_LOGGED = useSelector((state) => state.User.IS_LOGGED);
  const TOKEN = useSelector((state) => state.User.TOKEN);
  if (!IS_LOGGED) {
    history.push('/');
  }

  const [text, setText] = useState(initial);
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(false);
  const [title, setTitle] = useState('');
  const [formMode, setFormMode] = useState(null);

  const [stores, setStores] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const stores = await GET_STORE_LIST();
        setStores(stores);
        setLoading(true);
      } catch (error) {
        setLoading(true);
      }
    };
    fetchStores();
  }, []);

  const handleResetCover = () => setText({ ...text, logo: null });

  const handleAddStore = async () => {
    try {
      setModel(true);
      setTitle('Adding');
      let data = new FormData();
      data.append('name', text.name);
      data.append('logo', text.logo);
      data.append('user', TOKEN.id);
      const store = await ADD_STORE(data);
      setStores([...stores, store]);
      setText(initial);
      setModel(false);
    } catch (error) {
      setModel(false);
    }
  };

  const handleUpdateStore = async () => {
    try {
      setModel(true);
      setTitle('Updating');
      let data = new FormData();
      data.append('name', text.name);
      if (text.isLogoNew) {
        data.append('logo', text.logo);
        data.append('deleted_logo', text._id);
      }
      data.append('storeId', text._id);
      const store = await UPDATE_STORE(data);

      setFormMode(null);
      setModel(false);
      setStores(stores.map((item) => (store._id === item._id ? store : item)));
    } catch (error) {
      setModel(false);
    }
  };

  const handleDeleteStore = async (id) => {
    try {
      setModel(true);
      setTitle('Deleting');

      await DELETE_STORE(id);

      setModel(false);
      setStores(stores.filter((item) => id === item._id));
    } catch (error) {
      setModel(false);
    }
  };

  if (!loading) {
    return <Loading />;
  } else
    return (
      <div
        style={{
          padding: 20,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Saving visible={model} title={title} />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            align='center'
            variant='h3'
            style={{ marginBlock: 20, flex: 1 }}
          >
            Stores
          </Typography>
          <Button
            color='secondary'
            style={{ marginInline: 12 }}
            variant='contained'
            size='large'
            onClick={() => {
              setText(initial);
              setFormMode('Add');
            }}
          >
            <MdAdd fontSize={24} />
            Add New
          </Button>
        </div>

        {formMode && (
          <Paper
            elevation={8}
            style={{
              padding: 20,
              display: 'flex',
              flexDirection: 'column',
              width: '40%',
              alignSelf: 'center',
            }}
            square
          >
            <TextField
              label='Name'
              placeholder='Enter Name'
              onChange={(e) => {
                setText({ ...text, name: e.target.value });
              }}
              variant='outlined'
              style={{ width: '100%', marginBlock: 12 }}
              size='medium'
              value={text.name}
            />
            <div>
              <Typography variant='h6' style={{ marginBlock: 14 }}>
                Logo
              </Typography>
              {text.logo ? (
                <div style={{ position: 'relative', marginBlock: 8 }}>
                  <img
                    src={
                      text.isLogoNew
                        ? URL.createObjectURL(text.logo)
                        : text.logo.uri
                    }
                    alt={text.logo.name}
                    style={{
                      height: 250,
                      width: '100%',
                      objectFit: 'contain',
                    }}
                  />
                  <IconButton
                    style={{ position: 'absolute', right: 0, top: -10 }}
                    onClick={handleResetCover}
                  >
                    <AiOutlineClose />
                  </IconButton>
                </div>
              ) : (
                <div style={{ flexDirection: 'row' }}>
                  <input
                    accept='image/*'
                    style={{ display: 'none' }}
                    id='icon-button-file2'
                    type='file'
                    onChange={async (e) => {
                      if (e.target.files[0]?.size / (1024 * 1024) <= 10) {
                        let a = {
                          ...text,
                          logo: e.target.files[0],
                          isLogoNew: true,
                        };
                        setText(a);
                      } else {
                        toast.error('Image Size is Larger than 10MB');
                      }
                    }}
                  />
                  <label
                    htmlFor='icon-button-file2'
                    style={{ display: 'flex' }}
                  >
                    <Typography
                      variant='subtitle1'
                      align='left'
                      style={{ paddingTop: 10, marginLeft: 20 }}
                    >
                      Add Logo
                    </Typography>
                    <IconButton
                      color='primary'
                      aria-label='upload picture'
                      component='span'
                    >
                      <MdAddAPhoto />
                    </IconButton>
                  </label>
                </div>
              )}
            </div>
            <Button
              color='primary'
              style={{ marginInline: 12, alignSelf: 'flex-end' }}
              variant='contained'
              size='large'
              onClick={formMode === 'Add' ? handleAddStore : handleUpdateStore}
            >
              {formMode} Store
            </Button>
          </Paper>
        )}

        <Paper elevation={8} style={{ padding: 20, marginBlock: 20 }} square>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sr.</TableCell>
                  <TableCell>Logo</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stores.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>
                      <Avatar
                        src={`${ApiURL}/media/stores/logo/${item.logo.uri}?format=png`}
                        alt={item.logo.uri}
                        style={{
                          height: 80,
                          width: 80,
                          objectFit: 'contain',
                        }}
                        variant='rounded'
                      />
                    </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      <Button
                        color='secondary'
                        variant='contained'
                        size='large'
                        style={{
                          marginInline: 12,
                          backgroundColor: '#00a152',
                        }}
                        onClick={() => {
                          setText({
                            ...item,
                            logo: {
                              ...item.logo,
                              uri: `${ApiURL}/media/stores/logo/${item.logo.uri}?format=png`,
                            },
                            isLogoNew: false,
                          });
                          setFormMode('Edit');
                        }}
                      >
                        <MdEdit fontSize={24} />
                      </Button>
                      <Button
                        color='secondary'
                        style={{ marginInline: 12 }}
                        variant='contained'
                        size='large'
                        onClick={() => handleDeleteStore(item._id)}
                      >
                        <MdDeleteForever fontSize={24} />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    );
};

export default withRouter(AddStore);
