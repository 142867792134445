import React from 'react';
import {
  ListItem,
  List,
  Button,
  makeStyles,
  Typography,
  ListSubheader,
} from '@material-ui/core';
import { withRouter } from 'react-router';
import { MdCategory, MdDashboard, MdOutlinePlace } from 'react-icons/md';
import { AiOutlineLogout } from 'react-icons/ai';
import { BsFileRichtext } from 'react-icons/bs';
import {
  FaUserCog,
  FaUserPlus,
  FaHourglassHalf,
  FaPlus,
  FaStoreAlt,
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { USER_STATUS_OUT } from '../Redux/Actions/Actions';
const styles = makeStyles({
  SideBarBtn: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
    width: '100%',
    fontSize: 14,
    justifyContent: 'flex-start',
  },
});

const SideBar = (props) => {
  const IS_LOGGED = useSelector((state) => state.User.IS_LOGGED);
  if (!IS_LOGGED) {
    props.history.push('/');
  }
  const classes = styles();
  const dispatch = useDispatch();
  const User = useSelector((state) => state.User.TOKEN);
  const list = [
    {
      name: 'Dashboard',
      variant: props.var.main,
      icon: <MdDashboard fontSize={18} />,
      path: '',
      show: User?.role === 'admin',
    },
    {
      name: 'Contact By Users',
      variant: props.var.complaint_box,
      icon: <BsFileRichtext fontSize={18} />,
      path: 'contact-user',
      show: User?.role === 'admin',
    },
    {
      name: 'Pending Post',
      variant: props.var.pending_request,
      icon: <FaHourglassHalf fontSize={18} />,
      path: 'pending-post',
      show: User?.role === 'admin',
    },
    {
      name: 'Create Store',
      variant: props.var.create_store,
      icon: <FaStoreAlt fontSize={18} />,
      path: 'create-store',
      show: User?.role === 'admin',
    },
    {
      name: 'Create Category',
      variant: props.var.create_category,
      icon: <MdCategory fontSize={18} />,
      path: 'create-category',
      show: User?.role === 'admin',
    },
    // {
    //   name: "Create Promos",
    //   variant: props.var.create_promos,
    //   icon: <GiPriceTag fontSize={18} />,
    //   path: "create-promos",
    // },
    {
      name: 'Posts',
      variant: props.var.post,
      icon: <MdOutlinePlace fontSize={18} />,
      path: 'posts',
      show: User?.role === 'admin',
    },
    {
      name: 'Add Post',
      variant: props.var.add_post,
      icon: <FaPlus fontSize={18} />,
      path: 'add-post',
      show: User?.role === 'admin',
    },
    {
      name: 'Add Admin',
      variant: props.var.add_admin,
      icon: <FaUserPlus fontSize={18} />,
      path: 'add-admin',
      show: User?.role === 'admin',
    },
    {
      name: 'Setting',
      variant: props.var.setting,
      icon: <FaUserCog fontSize={18} />,
      path: 'setting',
      show: User?.role === 'admin',
    },
    {
      name: 'Log Out',
      variant: props.var.settings,
      icon: <AiOutlineLogout fontSize={18} />,
      path: '',
      show: true,
    },
  ];
  return (
    <div style={{ padding: 10 }} className='sidebar'>
      <>
        <List>
          <Typography
            align='center'
            variant='h5'
            style={{ marginBlock: 10, color: '#fff' }}
          >
            Admin Panel
          </Typography>
          <List
            subheader={
              <ListSubheader style={{ color: '#bdbdbd' }}>
                Options
              </ListSubheader>
            }
          ></List>
          {list.map(
            (item, i) =>
              item.show && (
                <ListItem key={i}>
                  <Button
                    color='secondary'
                    variant={item.variant}
                    className={classes.SideBarBtn}
                    size='small'
                    onClick={() => {
                      if (item.name === 'Log Out') {
                        dispatch(USER_STATUS_OUT());
                        props.history.push('/');
                      } else props.history.push('/dashboard/' + item.path);
                    }}
                  >
                    <span style={{ marginRight: 7 }}>{item.icon}</span>
                    <span>{item.name}</span>
                  </Button>
                </ListItem>
              )
          )}
        </List>
      </>
    </div>
  );
};

export default withRouter(SideBar);
