import { IconButton, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ALL_POST, DELETE_POST } from '../../Redux/Actions/PostAction';
import PostsCard from '../Card/PostsCard';
import Loading from '../Loading';
import { MdClose } from 'react-icons/md';
import PostDetails from '../PostDetails';
import EditPost from '../EditPost';
import { withRouter } from 'react-router';
import Saving from '../Saving';

const Post = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const posts = useSelector((state) => state.Post.all_posts);
  const dispatch = useDispatch();
  const [postId, setPostId] = useState('');
  const [visible, setVisible] = useState(false);
  const [model, setModel] = useState(false);
  const IS_LOGGED = useSelector((state) => state.User.IS_LOGGED);

  if (!IS_LOGGED) {
    history.push('/');
  }
  const handleClose = () => {
    setVisible('');
  };
  useEffect(() => {
    dispatch(
      ALL_POST(() => {
        setTimeout(() => {
          setLoading(true);
        }, 3000);
      })
    );
    // eslint-disable-next-line
  }, []);

  const handleDeletePost = (id) => {
    setModel(true);
    dispatch(
      DELETE_POST(
        id,
        () => {
          setModel(false);
        },
        () => {
          setModel(false);
        }
      )
    );
  };

  if (!loading) {
    return <Loading />;
  } else
    return (
      <div>
        <Saving visible={model} title='Deleting' />
        <Typography align='center' variant='h3' style={{ marginBlock: 20 }}>
          Post
        </Typography>
        <Paper
          elevation={8}
          style={{
            padding: 20,
            marginBlock: 20,
            width: '100%',
            alignSelf: 'center',
          }}
          square
        >
          <PostsCard
            data={posts}
            handleButtonView={(id) => {
              setPostId(id);
              setVisible('View');
            }}
            handleButtonEdit={(id) => {
              setPostId(id);
              setVisible('Edit');
            }}
            handleButtonDelete={(id) => {
              handleDeletePost(id);
            }}
          />
        </Paper>
        {visible === 'View' && (
          <Paper
            style={{
              padding: 20,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={handleClose} style={{ alignSelf: 'flex-end' }}>
              <MdClose />
            </IconButton>
            <PostDetails id={postId} />
          </Paper>
        )}
        {visible === 'Edit' && (
          <Paper
            style={{
              padding: 20,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <IconButton onClick={handleClose} style={{ alignSelf: 'flex-end' }}>
              <MdClose />
            </IconButton>
            <EditPost
              id={postId}
              handleClose={() => {
                setVisible('');
              }}
            />
          </Paper>
        )}
      </div>
    );
};

export default withRouter(Post);
