import * as ActionList from "../Actions/ActionsList";
const initialState = {
  user_post: [],
  all_posts: [],
  category_post: null,
  edit_post: null,
};
// eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionList.ALL_POST:
      return { ...state, all_posts: payload };
    case ActionList.USER_POST:
      return { ...state, user_post: payload };
    case ActionList.CATEGORY_POST:
      return { ...state, category_post: payload };
    case ActionList.EDIT_POST:
      return { ...state, edit_post: payload };
    default:
      return state;
  }
};
