import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CssBaseline } from '@material-ui/core';
import './App.css';

import Main from './Components/RightPanel/Main';
import Login from './Pages/Auth/Login';
import DashBoard from './Pages/Dashboard/DashBoard';
import NotAuthorized from './Pages/NotAuthorized';
import NotFound from './Pages/NotFound';

import ComplaintBox from './Components/RightPanel/ComplaintBox';
import Setting from './Components/RightPanel/Setting';
import PendingRequest from './Components/RightPanel/PendingRequest';
import AddAdmin from './Components/RightPanel/AddAdmin';
import Post from './Components/RightPanel/Post';
import NewPost from './Components/NewPost';
import AddPost from './Components/RightPanel/AddPost';
import AddStore from './Components/RightPanel/AddStore';
import AddCategory from './Components/RightPanel/AddCategory';

function App() {
  return (
    <Router>
      <div style={{ flex: 1 }}>
        <CssBaseline />
        <Switch>
          <Route path='/' exact component={Login} />
          <Route
            path='/dashboard'
            exact
            children={
              <DashBoard content={<Main />} var={{ main: 'contained' }} />
            }
          />
          <Route
            path='/dashboard/posts'
            exact
            children={
              <DashBoard content={<Post />} var={{ post: 'contained' }} />
            }
          />
          <Route
            path='/dashboard/add-post'
            exact
            children={
              <DashBoard
                content={<AddPost />}
                var={{ add_post: 'contained' }}
              />
            }
          />
          <Route
            path='/dashboard/create-store'
            exact
            children={
              <DashBoard
                content={<AddStore />}
                var={{ create_store: 'contained' }}
              />
            }
          />
          <Route
            path='/dashboard/create-category'
            exact
            children={
              <DashBoard
                content={<AddCategory />}
                var={{ create_category: 'contained' }}
              />
            }
          />
          <Route
            path='/dashboard/contact-user'
            exact
            children={
              <DashBoard
                content={<ComplaintBox />}
                var={{ complaint_box: 'contained' }}
              />
            }
          />
          <Route
            path='/dashboard/pending-post'
            exact
            children={
              <DashBoard
                content={<PendingRequest />}
                var={{ pending_request: 'contained' }}
              />
            }
          />

          <Route
            path='/dashboard/add-admin'
            exact
            children={
              <DashBoard
                content={<AddAdmin />}
                var={{ add_admin: 'contained' }}
              />
            }
          />
          <Route
            path='/dashboard/setting'
            exact
            children={
              <DashBoard content={<Setting />} var={{ setting: 'contained' }} />
            }
          />
          <Route
            path='/not-found'
            exact
            children={<DashBoard content={<NotFound />} />}
          />
          <Route path='/not-authorized' component={NotAuthorized} />
          <Redirect to='/not-found' />
        </Switch>
      </div>
      <ToastContainer />
    </Router>
  );
}

export default App;
