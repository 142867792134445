import {
  Avatar,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { MdAdd, MdAddAPhoto, MdDeleteForever, MdEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import Loading from '../Loading';
import Saving from '../Saving';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';
import {
  ADD_CATEGORY,
  DELETE_CATEGORY,
  GET_CATEGORIES_LIST,
  UPDATE_CATEGORY,
} from '../../services/categories';
import { AiOutlineClose } from 'react-icons/ai';
import { URL as ApiURL } from '../../config';

const initial = {
  name: '',
  logo: null,
  backgroundColor: '#ffffff',
  canUserAddPost: false,
};

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().min(8).required(),
});

const AddCategory = ({ history }) => {
  const IS_LOGGED = useSelector((state) => state.User.IS_LOGGED);
  if (!IS_LOGGED) {
    history.push('/');
  }

  const [text, setText] = useState(initial);
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(false);
  const [title, setTitle] = useState('');
  const [formMode, setFormMode] = useState(null);

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categories = await GET_CATEGORIES_LIST();
        setCategories(categories);
        setLoading(true);
      } catch (error) {
        setLoading(true);
      }
    };
    fetchCategories();
  }, []);

  const handleResetCover = () => setText({ ...text, logo: null });

  const handleAddCategory = async () => {
    try {
      setModel(true);
      setTitle('Adding');
      let data = new FormData();
      data.append('name', text.name);
      data.append('logo', text.logo);
      data.append('backgroundColor', text.backgroundColor);
      data.append('canUserAddPost', text.canUserAddPost);
      const category = await ADD_CATEGORY(data);
      setCategories([...categories, category]);
      setText(initial);
      setModel(false);
    } catch (error) {
      setModel(false);
    }
  };

  const handleUpdateCategory = async () => {
    try {
      setModel(true);
      setTitle('Updating');
      let data = new FormData();
      data.append('name', text.name);
      if (text.isLogoNew) {
        data.append('logo', text.logo);
        data.append('deleted_logo', text._id);
      }
      data.append('categoryId', text._id);
      data.append('canUserAddPost', text.canUserAddPost);
      data.append('backgroundColor', text.backgroundColor);
      const category = await UPDATE_CATEGORY(data);

      setFormMode(null);
      setModel(false);
      setCategories(
        categories.map((item) => (category._id === item._id ? category : item))
      );
    } catch (error) {
      setModel(false);
    }
  };

  const handleDeleteStore = async (id) => {
    try {
      setModel(true);
      setTitle('Deleting');

      await DELETE_CATEGORY(id);

      setModel(false);

      setCategories(categories.filter((item) => id === item._id));
    } catch (error) {
      setModel(false);
    }
  };

  const handleCanUserAddPostChange = (event) => {
    setText({ ...text, canUserAddPost: event.target.checked });
  };

  if (!loading) {
    return <Loading />;
  } else
    return (
      <div
        style={{
          padding: 20,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Saving visible={model} title={title} />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            align='center'
            variant='h3'
            style={{ marginBlock: 20, flex: 1 }}
          >
            Categories
          </Typography>
          <Button
            color='secondary'
            style={{ marginInline: 12 }}
            variant='contained'
            size='large'
            onClick={() => {
              setText(initial);
              setFormMode('Add');
            }}
          >
            <MdAdd fontSize={24} />
            Add New
          </Button>
        </div>

        {formMode && (
          <Paper
            elevation={8}
            style={{
              padding: 20,
              display: 'flex',
              flexDirection: 'column',
              width: '60%',
              alignSelf: 'center',
            }}
            square
          >
            <TextField
              label='Name'
              placeholder='Enter Name'
              onChange={(e) => {
                setText({ ...text, name: e.target.value });
              }}
              variant='outlined'
              style={{ width: '100%', marginBlock: 12 }}
              size='medium'
              value={text.name}
            />
            <FormGroup
              style={{
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={text.canUserAddPost}
                    onChange={handleCanUserAddPostChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label='Can User Add Post to this Category?'
                labelPlacement='start'
                style={{
                  margin: 0,
                }}
              />
            </FormGroup>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 4,
                marginBlock: 12,
                flexWrap: 'wrap',
              }}
            >
              <div style={{ flex: 1 }}>
                <Typography variant='h6' style={{ marginBlock: 14 }}>
                  Logo
                </Typography>
                {text.logo ? (
                  <div
                    style={{
                      position: 'relative',
                      marginBlock: 8,
                      backgroundColor: text.backgroundColor,
                      borderRadius: 18,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 8,
                    }}
                  >
                    <img
                      src={
                        text.isLogoNew
                          ? URL.createObjectURL(text.logo)
                          : text.logo.uri
                      }
                      alt={text.logo.name}
                      style={{
                        height: 250,
                        width: 250,
                        objectFit: 'contain',
                      }}
                    />
                    <IconButton
                      style={{ position: 'absolute', right: 0, top: 0 }}
                      onClick={handleResetCover}
                    >
                      <AiOutlineClose />
                    </IconButton>
                  </div>
                ) : (
                  <div style={{ flexDirection: 'row' }}>
                    <input
                      accept='image/*'
                      style={{ display: 'none' }}
                      id='icon-button-file2'
                      type='file'
                      onChange={async (e) => {
                        if (e.target.files[0]?.size / (1024 * 1024) <= 10) {
                          let a = {
                            ...text,
                            logo: e.target.files[0],
                            isLogoNew: true,
                          };
                          setText(a);
                        } else {
                          toast.error('Image Size is Larger than 10MB');
                        }
                      }}
                    />
                    <label
                      htmlFor='icon-button-file2'
                      style={{ display: 'flex' }}
                    >
                      <Typography
                        variant='subtitle1'
                        align='left'
                        style={{ paddingTop: 10, marginLeft: 20 }}
                      >
                        Add Logo
                      </Typography>
                      <IconButton
                        color='primary'
                        aria-label='upload picture'
                        component='span'
                      >
                        <MdAddAPhoto />
                      </IconButton>
                    </label>
                  </div>
                )}
              </div>
              <div style={{ flex: 1 }}>
                {text.logo && (
                  <React.Fragment>
                    <Typography variant='h6' style={{ marginBlock: 14 }}>
                      Background Color
                    </Typography>
                    <HexColorPicker
                      color={text.backgroundColor}
                      onChange={(e) => setText({ ...text, backgroundColor: e })}
                    />
                    <div className='colorPickerInput'>
                      <HexColorInput
                        color={text.backgroundColor}
                        onChange={(e) =>
                          setText({ ...text, backgroundColor: e })
                        }
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
            <Button
              color='primary'
              style={{ marginInline: 12, alignSelf: 'flex-end' }}
              variant='contained'
              size='large'
              onClick={
                formMode === 'Add' ? handleAddCategory : handleUpdateCategory
              }
            >
              {formMode} Category
            </Button>
          </Paper>
        )}

        <Paper elevation={8} style={{ padding: 20, marginBlock: 20 }} square>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sr.</TableCell>
                  <TableCell colSpan={2}>Logo</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>
                      <Avatar
                        src={`${ApiURL}/media/Categories/logo/${item.logo.uri}?format=png`}
                        alt={item.logo.uri}
                        style={{
                          height: 60,
                          width: 60,
                          objectFit: 'contain',
                          backgroundColor: item.backgroundColor,
                          borderRadius: 12,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: 8,
                        }}
                        variant='rounded'
                      />
                    </TableCell>
                    <TableCell colSpan={2}>{item.name}</TableCell>
                    <TableCell>
                      <Button
                        color='secondary'
                        variant='contained'
                        size='large'
                        style={{
                          marginInline: 12,
                          backgroundColor: '#00a152',
                        }}
                        onClick={() => {
                          setText({
                            ...item,
                            logo: {
                              ...item.logo,
                              uri: `${ApiURL}/media/Categories/logo/${item.logo.uri}?format=png`,
                            },
                            isLogoNew: false,
                          });
                          setFormMode('Edit');
                        }}
                      >
                        <MdEdit fontSize={24} />
                      </Button>
                      <Button
                        color='secondary'
                        style={{ marginInline: 12 }}
                        variant='contained'
                        size='large'
                        onClick={() => handleDeleteStore(item._id)}
                      >
                        <MdDeleteForever fontSize={24} />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    );
};

export default withRouter(AddCategory);
