import { Button, Paper, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { GET_USER_BY_USERNAME } from '../../Redux/Actions/Actions';
import UserCard from '../Card/UserCard';
import NewPost from '../NewPost';

const AddPost = () => {
  const allUsers = useSelector((state) => state.User.allUsers);
  const [User, setUser] = useState(null);
  const [name, setName] = useState('');
  const dispatch = useDispatch();
  return (
    <div>
      <Paper
        style={{
          padding: 20,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!User && (
          <React.Fragment>
            <div>
              <TextField
                label='User Name'
                variant='outlined'
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                style={{ width: '100%', marginBlock: 14 }}
              />
              <Button
                color='primary'
                variant='contained'
                size='large'
                onClick={() => {
                  dispatch(GET_USER_BY_USERNAME(name, () => {}));
                }}
                style={{
                  margin: 12,
                }}
              >
                Search
              </Button>
            </div>
            <UserCard
              data={allUsers}
              handleButton={(id) => {
                setUser(id);
              }}
            />
          </React.Fragment>
        )}
        {User && <NewPost userId={User} />}
      </Paper>
    </div>
  );
};

export default AddPost;
