import { toast } from 'react-toastify';
import API from '../API/API';

const SUCCESS = (msg) => {
  return toast.success(msg, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const ERROR = (msg) => {
  return toast.error(msg, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const GET_STORE_LIST = async () => {
  try {
    const res = await API.get('/admin/stores', {});
    return res.data;
  } catch (error) {
    if (error.response) {
      ERROR(error.response.data);
      throw new Error(error.response.data);
    } else if (error.request) {
      ERROR('Bad Request');
      throw new Error('Bad Request');
    } else {
      ERROR(error.message);
      throw new Error(error.message);
    }
  }
};

export const ADD_STORE = async (data) => {
  try {
    const res = await API.post('/stores/byAdmin', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    SUCCESS('Store Added Successfully!');
    return res.data;
  } catch (error) {
    if (error.response) {
      ERROR(error.response.data);
      throw new Error(error.response.data);
    } else if (error.request) {
      ERROR('Bad Request');
      throw new Error('Bad Request');
    } else {
      ERROR(error.message);
      throw new Error(error.message);
    }
  }
};

export const UPDATE_STORE = async (data) => {
  try {
    const res = await API.patch('/stores/byAdmin', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    SUCCESS('Store Updated Successfully!');
    return res.data;
  } catch (error) {
    if (error.response) {
      ERROR(error.response.data);
      throw new Error(error.response.data);
    } else if (error.request) {
      ERROR('Bad Request');
      throw new Error('Bad Request');
    } else {
      ERROR(error.message);
      throw new Error(error.message);
    }
  }
};

export const DELETE_STORE = async (id) => {
  try {
    const res = await API.delete('/stores/byAdmin/' + id);
    SUCCESS('Store Deleted Successfully!');
    return res.data;
  } catch (error) {
    if (error.response) {
      ERROR(error.response.data);
      throw new Error(error.response.data);
    } else if (error.request) {
      ERROR('Bad Request');
      throw new Error('Bad Request');
    } else {
      ERROR(error.message);
      throw new Error(error.message);
    }
  }
};
