import {
  Avatar,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';

import React, { useLayoutEffect, useMemo, useState } from 'react';
import { MdAddAPhoto, MdClose } from 'react-icons/md';
import { RiVideoAddFill } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ADD_POST, ALL_POST } from '../Redux/Actions/PostAction';
import Saving from './Saving';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import { GOOGLE_API_KEY } from '../config';
import Loading from './Loading';
import { BiCurrentLocation } from 'react-icons/bi';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import moment from 'moment';
import { AiOutlineClose } from 'react-icons/ai';
import { GET_STORE_LIST } from '../services/stores';
import { GET_CATEGORIES_LIST } from '../services/categories';

const libraries = ['places', 'geometry', 'drawing'];

const ERROR = (msg) => {
  return toast.error(msg, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const initial = {
  pic: [],
  cover: null,
  address: '',
  short_description: '',
  category: '',
  description: '',
  ph: '',
  expiresAt: new Date(),
  website: '',
  email: '',
  video: null,
  store: '',
};

const NewPost = ({ userId }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries,
  });

  const [offer, setOffer] = useState(initial);
  const dispatch = useDispatch();
  const [model, setModel] = useState(false);
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const [center, setCenter] = useState(null);
  const [categories, setCategories] = useState([]);
  const GroceryCategory = useMemo(
    () => categories.find((c) => c.name === 'Grocery Stores')?._id,
    [categories]
  );
  const getCurrentPosition = () =>
    new Promise((resolve, reject) => {
      try {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            resolve({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          (error) => reject(error)
        );
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });

  const getPositionPermission = async () => {
    try {
      if (!('geolocation' in navigator))
        throw new Error('Location Not Available!');
      const location = await navigator.permissions.query({
        name: 'geolocation',
      });
      if (location.state !== 'granted') {
        navigator.geolocation.getCurrentPosition(() => {});
        location.addEventListener('change', async () => {
          const location = await getCurrentPosition();
          setLocation(location);
          setCenter(location);
          setLoading(true);
        });
      } else {
        const cord = await getCurrentPosition();
        setLocation(cord);
        setCenter(cord);
        setTimeout(() => {
          setLoading(true);
        }, 3000);
      }
    } catch (error) {
      setLoading(true);
      alert(error.message);
    }
  };

  useLayoutEffect(() => {
    const fetchStores = async () => {
      try {
        const stores = await GET_STORE_LIST();

        setStores(stores);
      } catch (error) {}
    };
    const fetchCategories = async () => {
      try {
        const categories = await GET_CATEGORIES_LIST();
        setCategories(categories);
      } catch (error) {}
    };
    Promise.allSettled([
      fetchCategories(),
      fetchStores(),
      getPositionPermission(),
    ]);
  }, []);

  const handleResetCover = () => setOffer({ ...offer, cover: null });

  const handelSubmit = () => {
    try {
      if (!offer.cover) {
        ERROR('Post Cover is required!');
        return;
      }

      const isGroceryCategory = GroceryCategory === offer.category;

      if (isGroceryCategory && !offer.store) {
        ERROR('Associated store is required');
        return;
      }
      let data = new FormData();

      const locationPointData = selectedLocation || location;

      let locationData = {
        type: 'Point',
        coordinates: [locationPointData.lng, locationPointData.lat],
      };

      data.append('address', offer.address);
      data.append('location', JSON.stringify(locationData));
      data.append('category', offer.category);
      data.append('short_description', offer.short_description);
      data.append('description', offer.description);
      data.append('ph', offer.ph);
      data.append('website', offer.website);
      data.append('expiresAt', moment(offer.expiresAt).toISOString());
      data.append('email', offer.email);
      data.append('id', userId);
      data.append('cover', offer.cover);
      if (isGroceryCategory) data.append('store', offer.store);

      for (const i of offer.pic) {
        data.append('pic', i);
      }

      if (offer.video) {
        data.append('video', offer.video);
      }
      console.log(offer, data);
      for (var pair of data.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }
      setModel(true);
      dispatch(
        ADD_POST(
          data,
          () => {
            dispatch(ALL_POST(() => {}));
            setTimeout(() => {
              setModel(false);
              setOffer(initial);
            }, 3000);
          },
          () => {
            setModel(false);
          }
        )
      );
    } catch (error) {
      ERROR(error.message);
    }
  };

  if (!loading) return <Loading />;
  else if (!location)
    return (
      <React.Fragment>
        <div style={{ textAlign: 'center', fontSize: 24, fontWeight: 500 }}>
          Location not Available !
        </div>
      </React.Fragment>
    );
  return (
    <div>
      <Saving visible={model} title='Saving' />
      <div>
        {isLoaded && (
          <React.Fragment>
            <div>
              <GooglePlacesAutocomplete
                apiKey={GOOGLE_API_KEY}
                onLoadFailed={(er) => console.log(er)}
                debounce={1000}
                selectProps={{
                  onChange: async (data) => {
                    const result = await geocodeByPlaceId(data.value.place_id);
                    setCenter({
                      lat: result[0].geometry.location.lat(),
                      lng: result[0].geometry.location.lng(),
                    });
                    setSelectedLocation({
                      lat: result[0].geometry.location.lat(),
                      lng: result[0].geometry.location.lng(),
                    });
                  },
                }}
              />
            </div>
            <div
              style={{
                height: 700,
                width: '100%',
                marginTop: 12,
              }}
            >
              <GoogleMap
                mapContainerStyle={{
                  height: 700,
                  width: '100%',
                }}
                center={center}
                zoom={17}
                onLoad={(map) => {
                  // const bounds = new window.google.maps.LatLngBounds();
                  // map.fitBounds(bounds);
                }}
                onUnmount={(map) => {
                  // do your stuff before map is unmounted
                }}
                onClick={(value) => {
                  setSelectedLocation({
                    lat: value.latLng.lat(),
                    lng: value.latLng.lng(),
                  });
                }}
              >
                <Marker position={location} />
                {selectedLocation && (
                  <Marker
                    position={selectedLocation}
                    draggable={true}
                    onDragEnd={(value) => {
                      setSelectedLocation({
                        lat: value.latLng.lat(),
                        lng: value.latLng.lng(),
                      });
                    }}
                  />
                )}
                <div
                  style={{
                    position: 'absolute',
                    bottom: '28%',
                    right: '0.7%',
                  }}
                >
                  <IconButton
                    style={{ backgroundColor: 'white' }}
                    onClick={async () => {
                      const location = await getCurrentPosition();
                      setLocation(location);
                      setCenter(location);
                    }}
                  >
                    <BiCurrentLocation />
                  </IconButton>
                </div>
              </GoogleMap>
            </div>
          </React.Fragment>
        )}

        <div>
          <Typography variant='h6' style={{ marginBlock: 14 }}>
            Cover
          </Typography>
          {offer.cover ? (
            <div style={{ position: 'relative', width: 300 }}>
              <Avatar
                src={URL.createObjectURL(offer.cover)}
                alt='Image'
                style={{
                  height: 250,
                  width: 250,
                }}
                variant='rounded'
              />
              <IconButton
                style={{ position: 'absolute', right: 0, top: -10 }}
                onClick={handleResetCover}
              >
                <AiOutlineClose />
              </IconButton>
            </div>
          ) : (
            <div style={{ flexDirection: 'row' }}>
              <input
                accept='image/*'
                style={{ display: 'none' }}
                id='icon-button-file2'
                type='file'
                onChange={async (e) => {
                  if (e.target.files[0]?.size / (1024 * 1024) <= 10) {
                    let a = { ...offer, cover: e.target.files[0] };
                    setOffer(a);
                  } else {
                    toast.error('Image Size is Larger than 10MB');
                  }
                }}
              />
              <label htmlFor='icon-button-file2' style={{ display: 'flex' }}>
                <Typography
                  variant='subtitle1'
                  align='left'
                  style={{ paddingTop: 10, marginLeft: 20 }}
                >
                  Add Cover
                </Typography>
                <IconButton
                  color='primary'
                  aria-label='upload picture'
                  component='span'
                >
                  <MdAddAPhoto />
                </IconButton>
              </label>
            </div>
          )}
        </div>

        <div>
          <Typography variant='h6' style={{ marginBlock: 14 }}>
            Description
          </Typography>
          <TextField
            multiline
            label='Description'
            variant='outlined'
            value={offer.description}
            onChange={(e) => {
              setOffer({ ...offer, description: e.target.value });
            }}
            style={{ width: '100%' }}
          />
        </div>

        <div>
          <Typography variant='h6' style={{ marginBlock: 14 }}>
            Category
          </Typography>
          <FormControl
            variant='outlined'
            style={{ width: '100%', marginBlock: 14 }}
          >
            <InputLabel id='demo-simple-select-outlined-label'>
              Category
            </InputLabel>
            <Select
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              value={offer.category}
              onChange={(e) => {
                setOffer({
                  ...offer,
                  category: e.target.value,
                });
              }}
              label={offer.category}
            >
              {categories.map((item, i) => (
                <MenuItem value={item._id} key={i}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {offer.category === GroceryCategory && (
          <div>
            <Typography variant='h6' style={{ marginBlock: 14 }}>
              Associated Store
            </Typography>
            <FormControl
              variant='outlined'
              style={{ width: '100%', marginBlock: 14 }}
            >
              <InputLabel id='demo-simple-select-outlined-label-2'>
                Associated Store
              </InputLabel>

              <Select
                labelId='demo-simple-select-outlined-label-2'
                id='demo-simple-select-outlined-2'
                value={stores.find((i) => i._id === offer.store)?.name}
                onChange={(e) => {
                  setOffer({
                    ...offer,
                    store: stores.find((i) => i.name === e.target.value)._id,
                  });
                }}
                label={stores.find((i) => i._id === offer.store)?.name || ''}
              >
                {stores.map((item, i) => (
                  <MenuItem value={item.name} key={i}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}

        <div>
          <Typography variant='h6' style={{ marginBlock: 14 }}>
            Expires At
          </Typography>
          <DateTimePicker
            label='Expires At'
            inputVariant='outlined'
            value={offer.expiresAt}
            onChange={(e) => {
              let a = { ...offer };
              a.expiresAt = e;
              setOffer(a);
            }}
            style={{ width: '100%' }}
          />
        </div>

        <div>
          <Typography variant='h6' style={{ marginBlock: 14 }}>
            Short Description
          </Typography>
          <TextField
            label='Short Description'
            variant='outlined'
            value={offer.short_description}
            onChange={(e) => {
              let a = { ...offer };
              a.short_description = e.target.value;
              setOffer(a);
            }}
            multiline
            style={{ width: '100%' }}
          />
        </div>

        <div>
          <Typography variant='h6' style={{ marginBlock: 14 }}>
            More Picture
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              overflow: 'auto',
              width: '98%',
            }}
          >
            {offer.pic.map((item, i) => {
              return (
                <div key={i} style={{ position: 'relative' }}>
                  <IconButton
                    onClick={() => {
                      let a = { ...offer };
                      a.pic = a.pic.filter((pic) => pic !== item);
                      setOffer(a);
                    }}
                    style={{ position: 'absolute', right: 4, zIndex: 1000 }}
                  >
                    <MdClose color='gray' />
                  </IconButton>
                  <Avatar
                    src={URL.createObjectURL(item)}
                    alt='Image'
                    style={{
                      height: 250,
                      width: 250,
                      margin: 7,
                    }}
                    variant='rounded'
                  />
                </div>
              );
            })}
          </div>

          <div style={{ flexDirection: 'row' }}>
            <input
              accept='image/*'
              style={{ display: 'none' }}
              id='icon-button-file'
              type='file'
              onChange={async (e) => {
                if (e.target.files[0]?.size / (1024 * 1024) <= 10) {
                  let a = { ...offer };
                  a.pic.push(e.target.files[0]);
                  setOffer(a);
                } else {
                  toast.error('Image Size is Larger than 10MB');
                }
              }}
            />
            <label htmlFor='icon-button-file' style={{ display: 'flex' }}>
              <Typography
                variant='subtitle1'
                align='left'
                style={{ paddingTop: 10, marginLeft: 20 }}
              >
                Add Picture
              </Typography>
              <IconButton
                color='primary'
                aria-label='upload picture'
                component='span'
              >
                <MdAddAPhoto />
              </IconButton>
            </label>
          </div>
        </div>

        <div style={{ flexDirection: 'row' }}>
          <Typography variant='h6' style={{ marginBlock: 14 }}>
            Video
          </Typography>
          <input
            accept='video/*'
            style={{ display: 'none' }}
            id='icon-button-file3'
            type='file'
            onChange={(e) => {
              if (e.target.files[0]?.size / (1024 * 1024) <= 10) {
                let a = { ...offer };
                a.video = e.target.files[0];
                setOffer(a);
                console.log(a.video);
              } else {
                toast.error('Image Size is Larger than 10MB');
              }
            }}
          />
          <label htmlFor='icon-button-file3' style={{ display: 'flex' }}>
            <Typography
              variant='subtitle1'
              align='left'
              style={{ paddingTop: 10, marginLeft: 20 }}
            >
              Upload Video
            </Typography>
            <IconButton
              color='secondary'
              aria-label='upload video'
              component='span'
            >
              <RiVideoAddFill />
            </IconButton>
            {offer.video === null ? null : (
              <Typography
                variant='subtitle1'
                style={{ paddingTop: 10 }}
                color='primary'
              >
                {offer.video?.name}{' '}
                {(offer.video?.size / (1024 * 1024)).toFixed(4)} MB
              </Typography>
            )}
          </label>
        </div>

        <div>
          <Typography variant='h6' style={{ marginBlock: 14 }}>
            Website
          </Typography>
          <TextField
            label='Website'
            variant='outlined'
            value={offer.website}
            onChange={(e) => {
              setOffer({ ...offer, website: e.target.value });
            }}
            style={{ width: '100%', marginBlock: 14 }}
          />
        </div>

        <div>
          <Typography variant='h6' style={{ marginBlock: 14 }}>
            Phone
          </Typography>
          <TextField
            label='Phone'
            variant='outlined'
            value={offer.ph}
            onChange={(e) => {
              setOffer({ ...offer, ph: e.target.value });
            }}
            style={{ width: '100%', marginBlock: 14 }}
          />
        </div>

        <div>
          <Typography variant='h6' style={{ marginBlock: 14 }}>
            Email
          </Typography>
          <TextField
            label='Email'
            variant='outlined'
            value={offer.email}
            onChange={(e) => {
              setOffer({ ...offer, email: e.target.value });
            }}
            style={{ width: '100%', marginBlock: 14 }}
          />
        </div>

        <div>
          <Typography variant='h6' style={{ marginBlock: 14 }}>
            Address
          </Typography>
          <TextField
            label='Address'
            variant='outlined'
            value={offer.address}
            onChange={(e) => {
              setOffer({ ...offer, address: e.target.value });
            }}
            style={{ width: '100%' }}
          />
        </div>

        <div style={{ marginBlock: 14 }}>
          <Button
            color='primary'
            variant='contained'
            size='large'
            onClick={handelSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewPost;
