import * as ActionList from './ActionsList';
import FreeFindz from '../../API/API';
import { toast } from 'react-toastify';

const SUCCESS = (msg) => {
  return toast.success(msg, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const ERROR = (msg) => {
  return toast.error(msg, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const POST = (payload) => ({
  type: ActionList.ALL_POST,
  payload,
});

export const USER_POST = (payload) => ({
  type: ActionList.USER_POST,
  payload,
});

export const CATEGORY_POST = (payload) => ({
  type: ActionList.CATEGORY_POST,
  payload,
});

export const EDIT_POST = (payload) => ({
  type: ActionList.EDIT_POST,
  payload,
});

export const ADD_POST_TWO = (data, callback) => {
  return async (dispatch) => {
    await FreeFindz.post(`/post`, data)
      .then((response) => {
        SUCCESS('Post Added!');
        callback();
      })
      .catch((error) => {
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR('Bad Request!');
        } else {
          ERROR('Network Error!');
        }
        callback();
      });
  };
};

export const ADD_POST = (data, callback, errorCallback) => {
  return async (dispatch) => {
    await FreeFindz.post(`/post/byAdmin`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        callback();
      })
      .catch((error) => {
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR('Bad Request!');
        } else {
          ERROR('Network Error!');
        }
        errorCallback();
      });
  };
};

export const ADD_VIDEO = (formData, id, toast, callback) => {
  return async (dispatch) => {
    await FreeFindz.post(`/post/addVideo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: { id },
    })
      .then((response) => {
        SUCCESS(toast);
        callback();
      })
      .catch((error) => {
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR('Bad Request!');
        } else {
          ERROR('Network Error!');
        }
        callback();
      });
  };
};

export const UPDATE_POST = (data, callback) => {
  return async (dispatch) => {
    await FreeFindz.put(`/post/byAdmin`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        SUCCESS('Post Updated!');
        callback();
      })
      .catch((error) => {
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR('Bad Request!');
        } else {
          ERROR('Network Error!');
        }
        callback();
      });
  };
};

export const ALL_POST = (callback) => {
  return async (dispatch) => {
    await FreeFindz.get(`/admin/posts/`)
      .then((response) => {
        dispatch(POST(response.data));
        callback();
      })
      .catch((error) => {
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR('Bad Request!');
        } else {
          ERROR('Network Error!');
        }
        callback();
      });
  };
};

export const POST_BY_CATEGORY = (type, callback) => {
  return async (dispatch) => {
    await FreeFindz.get(`/post/category/${type}`)
      .then((response) => {
        dispatch(CATEGORY_POST(response.data));
        callback();
      })
      .catch((error) => {
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR('Bad Request!');
        } else {
          ERROR('Network Error!');
        }
        callback();
      });
  };
};

export const DELETE_POST = (id, callback, errorCb) => {
  return async (dispatch) => {
    await FreeFindz.delete(`/post/${id}`)
      .then((response) => {
        SUCCESS('Post Deleted Successfully!');
        dispatch(ALL_POST(() => {}));
        callback();
      })
      .catch((error) => {
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR('Bad Request!');
        } else {
          ERROR('Network Error!');
        }
        errorCb();
      });
  };
};

export const GET_USER_POST = (id, callback) => {
  return async (dispatch) => {
    await FreeFindz.get(`/post/${id}`)
      .then((response) => {
        dispatch(USER_POST(response.data));
        callback();
      })
      .catch((error) => {
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR('Bad Request!');
        } else {
          ERROR('Network Error!');
        }
        callback();
      });
  };
};

export const GET_POST_BY_ID = (id, callback) => {
  return async (dispatch) => {
    await FreeFindz.get(`/post/id/${id}`)
      .then((response) => {
        dispatch(EDIT_POST(response.data));
        console.log(response.data);
        callback(response.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR('Bad Request!');
        } else {
          ERROR(error.message);
        }
      });
  };
};

export const ADD_POST_COMMENT = (data, callback) => {
  return async (dispatch) => {
    await FreeFindz.post(`/post/addComment/`, data)
      .then((response) => {
        dispatch(ALL_POST(() => {}));
        SUCCESS('Comment Added');
        callback();
      })
      .catch((error) => {
        if (error.response) {
          ERROR(error.response.data);
        } else if (error.request) {
          ERROR('Bad Request!');
        } else {
          ERROR('Network Error!');
        }
        callback();
      });
  };
};
